import React, { useEffect, useState } from 'react'
import { GetStaticProps, InferGetStaticPropsType } from 'next'
import Link from 'next/link'
import Image from 'next/image'
import dynamic from 'next/dynamic'

import { HiOutlineArrowNarrowRight } from 'react-icons/hi'

import { Slider1, Slider2, Slider3 } from '@/services/slider-data'

import SEO from '@/components/SEO'
import Swiper, { SwiperSlide } from '@/components/Swiper'
import Button from '@/components/Button'
import BackToTop from '@/components/BackToTop'

import { Container } from '@/styles/pages/Home'
import createOgImage from '@/lib/createOgImage'

const ImageModal = dynamic(() => import('@/components/ImageModal'), {
  ssr: false
})

interface HomeStaticProps {
  ogImage: string
}

type HomeProps = InferGetStaticPropsType<GetStaticProps<HomeStaticProps>>
export default function Home(props: HomeProps) {
  const [slidesPerView, setSlidesPerView] = useState(3.5)

  useEffect(() => {
    const resize = function () {
      const size = document.body.offsetWidth

      size <= 1300 && setSlidesPerView(3.5)
      size <= 900 && setSlidesPerView(2)
    }

    resize()
    window.addEventListener('resize', resize.debounce(250))
  }, [])

  return (
    <Container>
      <SEO
        title="Carlos Miguel"
        description="Programador FullStack UI/UX Designer, apaixonado pelas melhores tecnologias de desenvolvimento back-end, front-end e mobile. Fascinado por aprendizado e por JavaScript."
        image={props.ogImage}
      />

      {/* <BackToTop /> */}

      <div className="header">
        <div className="title-box">
          <h1>Carlos</h1>
          <span>Especialista em desenvolvimento FullStack</span>

          <Link href="https://www.instagram.com/solrachix">
            <a target="_blank" rel="noopener noreferrer">
              <Button type="button" text="Converse comigo" />
            </a>
          </Link>
        </div>

        <div className="HomeMockups__Wrapper">
          <div className="mockup image-1">
            <img src="/images/mockups/mockup-card2.svg" alt="Mockup card 2" />
          </div>
          <div className="mockup image-2">
            <img src="/images/mockups/mockup-card2.svg" alt="Mockup card 2" />
          </div>
          <div className="mockup image-3">
            <img
              src="/images/mockups/mockup-content.svg"
              alt="Mockup content"
            />
          </div>
          <div className="mockup image-4">
            <img src="/images/mockups/mockup2-bg.svg" alt="Mockup card 2" />
          </div>
          <div className="mockup image-5">
            <img src="/images/mockups/mockup3-bg.svg" alt="Mockup card 2" />
          </div>
        </div>
      </div>

      <section className="bio">
        <div className="row">
          <div className="col">
            <h2>Eu amo criar algo simples e limpo</h2>
          </div>
          <div className="col">
            <p>
              Um programador de interfaces e sistemas, atualmente morando em São
              Paulo. Gosta de explorar designs minimalistas e com boa
              funcionalidade.
            </p>
            <p>
              Pode passar horas todos os dias programando ou explorando designs.
              Você pode ver algumas dos meu design no{' '}
              <a href="http://instagram.com/solrachix">Instagram</a>.
            </p>
            <p>
              Também gosto de escrever dicas, a maior parte da minha dicas é
              sobre programação, podem todas ser encontradas no meu{' '}
              <a href="http://instagram.com/solrachix">Instagram</a> também.
            </p>
            <p>
              Sou ativo no instagram, se você tiver alguma dúvida não hesite em
              me mencionar ou chamar no direct, com certeza será um bate-papo
              interessante.
            </p>
          </div>
        </div>
      </section>

      <section className="projects">
        <span>Janeiro de 2021</span>
        <h3>Interface dark</h3>
        <p>
          Sempre gostei do modo Dark nas interfaces, seja no Whatsapp, ou até
          mesmo a interface escura do Discord. E Eu acho que muitos tem essa
          paixão que nem eu, então, porque não colocar modo Dark nas interface?
        </p>

        <div className="mockups">
          <img src="/images/mockups/podcastr-2.png" alt="podcastr dark mode" />
          <img src="/images/mockups/podcastr-3.png" alt="podcastr dark mode" />
          <img src="/images/mockups/podcastr-1.png" alt="podcastr dark mode" />
        </div>
      </section>

      <section className="projects">
        <span>Janeiro de 2021</span>
        <h3>Landing pages</h3>
        <p>
          Realmente gosto de explorar a página de destino. Principalmente a
          parte de customização do layout. Muito satisfeito se você puder fazer
          um layout único, fácil de ver, mas ainda assim claro as informações.
        </p>
        <ImageModal src="/images/mockups/landing-page.png" alt="landing page" />
      </section>

      <section className="projects">
        <span>Janeiro de 2021</span>
        <h3>Formulários de cadastro</h3>
        <p>
          Formulários de cadastro é algo em que os design/desenvolvedores
          parecem esquecerem, além de terem muitos que confundem os usuários e
          não seja agradável eu gosto de deixar o mais confortável possível.
        </p>
        <div className="row">
          <div className="col">
            <ImageModal src="/images/mockups/form-1.svg" alt="form template" />
          </div>

          <div className="col">
            <ImageModal src="/images/mockups/form-2.svg" alt="form template" />
          </div>
        </div>
      </section>

      <section className="projects">
        <span>Maio de 2020</span>
        <div className="row">
          <h3>Ecoleta</h3>
          <Image
            loading="lazy"
            src="/images/mockups/ecoleta-logo.svg"
            alt="Ecoleta logo"
            width={71}
            height={72}
          />
        </div>
        <p>
          Ecoleta é um projeto desenvolvido a partir da Semana Internacional do
          Meio Ambiente. O objetivo é conectar pessoas a empresas que coletam
          resíduos específicos, como lâmpadas, baterias, etc.
        </p>

        <span className="alert">
          Mova para o lado <HiOutlineArrowNarrowRight />
        </span>
        <Swiper freeMode spaceBetween={50} slidesPerView={slidesPerView}>
          {Slider1.map((src, index) => (
            <SwiperSlide key={index}>
              <Image
                loading="lazy"
                src={src}
                alt={`Ecoleta photo ${index + 1}`}
                width={400}
                height={850}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </section>

      <section className="projects">
        <span>Abril de 2021</span>
        <div className="row">
          <h3>Plant Manager</h3>
          <Image
            loading="lazy"
            src="/images/mockups/plantManager-logo.svg"
            alt="PlantManager logo"
            width={71}
            height={72}
          />
        </div>
        <p>
          O Plant Manager é um app para lembrar as pessoas de regar as suas
          plantinhas.
        </p>

        <span className="alert">
          Mova para o lado <HiOutlineArrowNarrowRight />
        </span>
        <Swiper freeMode spaceBetween={50} slidesPerView={slidesPerView}>
          {Slider2.map((src, index) => (
            <SwiperSlide key={index}>
              <Image
                loading="lazy"
                src={src}
                alt={`plantManager photo ${index}`}
                width={350}
                height={750}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <Swiper freeMode spaceBetween={50} slidesPerView={slidesPerView}>
          {Slider3.map((src, index) => (
            <SwiperSlide key={index}>
              <Image
                loading="lazy"
                src={src}
                alt={`plantManager dark photo ${index}`}
                width={350}
                height={750}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
    </Container>
  )
}

export const getStaticProps: GetStaticProps<HomeStaticProps> = async () => {
  const ogImage = await createOgImage({
    title: 'Portfolio'
  })

  return {
    revalidate: 86400,
    props: {
      ogImage
    }
  }
}
