import Home from '@/assets/icons/home.svg'
import About from '@/assets/icons/about.svg'
import projects from '@/assets/icons/projects.svg'
import instagram from '@/assets/icons/instagram.svg'
import conversai from '@/assets/icons/conversai.svg'

const routes = [
  {
    title: 'Home',
    route: '/',
    icon: Home
  },
  {
    title: 'Sobre mim',
    route: '/about',
    icon: About
  },
  // {
  //   route: '/#2',
  //   icon: FiAward
  // },
  {
    title: 'Projetos',
    route: '/projects',
    icon: projects
  },
  {
    title: 'Instagram',
    route: 'https://www.instagram.com/solrachix',
    icon: instagram,
    socialIcon: true
  },
  {
    title: 'ConversAI',
    route: 'https://chat.solrachix.com',
    icon: conversai,
    socialIcon: true
  }
  // {
  //   title: 'Proposta',
  //   route: '/proposal',
  //   icon: proposals
  // }
]

export default routes
