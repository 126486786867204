import { ReactElement } from 'react'
import clsx from 'clsx'

import routes from '@/routes'

import { useRouter } from 'next/router'
import Link from 'next/link'

import { Container } from './styles'
export default function index(): ReactElement {
  const router = useRouter()

  const handleClick = e => {
    // e.preventDefault()

    const self = e.currentTarget
    const li = self.parentNode

    if (!li.classList.contains('active')) {
      const active: HTMLLIElement = Array.from(li.parentNode.children).find(
        (elem: HTMLLIElement) => elem.classList.contains('active')
      )

      const up = document.createElement('em')
      const up2 = document.createElement('em')
      const up3 = document.createElement('em')
      const down = document.createElement('em')
      const down2 = document.createElement('em')
      const down3 = document.createElement('em')

      up.classList.add('up')
      up2.classList.add('up2')
      up3.classList.add('up3')
      down.classList.add('down')
      down2.classList.add('down2')
      down3.classList.add('down3')

      active.appendChild(up)
      active.appendChild(up2)
      active.appendChild(up3)
      active.appendChild(down)
      active.appendChild(down2)
      active.appendChild(down3)

      // document.createElement('em').classList.add('up').insertAdjacentHTML(active)
      // document.createElement('em').classList.add('up2').insertAdjacentHTML(active)
      // document.createElement('em').classList.add('up3').insertAdjacentHTML(active)
      // document.createElement('em').classList.add('down').insertAdjacentHTML(active)
      // document.createElement('em').classList.add('down2').insertAdjacentHTML(active)
      // document.createElement('em').classList.add('down3').insertAdjacentHTML(active)

      const em: Element[] = Array.from(active.children).filter(
        (elem: HTMLLIElement) => elem.getAttribute('name') === 'em'
      )
      const offset = 0
      let multipleSteps = false
      const left = active.offsetWidth
      const leftNew =
        self.parentNode.parentNode.parentNode.parentNode.offsetWidth
      const toRight = active.index() < li.index()

      if (toRight) {
        multipleSteps = li.index() - active.index() > 1
        self.classList.add('toRight')
        Array.from(active.children).map(
          (elem: HTMLLIElement) =>
            elem.getAttribute('name') === 'a' &&
            elem.classList.add('removeRight')
        )
        em.map((elem: HTMLLIElement) => {
          elem.style.setProperty('--offset', `${leftNew - left}px`)
          // elem.style.left = `${leftNew - left}px`
        })
      } else {
        multipleSteps = active.index() - li.index() > 1
        self.classList.add('toLeft')
        Array.from(active.children).map(
          (elem: HTMLLIElement) =>
            elem.getAttribute('name') === 'a' &&
            elem.classList.add('removeLeft')
        )
        em.map((elem: HTMLLIElement) => {
          elem.style.setProperty('--offset', `${(left - leftNew) * -1}px`)
          // elem.style.left = `${(left - leftNew) * -1}px`
        })
      }

      em.map((elem: HTMLLIElement) => {
        elem.classList.add('move')
        elem.addEventListener('click', () => {
          elem.classList.toggle('multipleSteps', multipleSteps)
        })
      })

      setTimeout(() => {
        active.classList.remove('active')
        Array.from(active.children).map(
          (elem: HTMLLIElement) =>
            elem.getAttribute('name') === 'a' &&
            elem.classList.remove('removeRight removeLeft')
        )
      }, 300)

      setTimeout(() => {
        self.classList.remove('toRight')
        self.classList.remove('toLeft')
        self.parentNode.classList.add('active')
        em.remove()
      }, 600)
    }
  }
  //   $('.tabbar ul li a').on('click touch', function(e) {

  //     e.preventDefault();

  //     var self = $(this);
  //     var li = self.parent();

  //     if(!li.hasClass('active')) {

  //         var active = self.parent().parent().children('.active');

  //         $('<em />').addClass('up').appendTo(active);
  //         $('<em />').addClass('up2').appendTo(active);
  //         $('<em />').addClass('up3').appendTo(active);
  //         $('<em />').addClass('down').appendTo(active);
  //         $('<em />').addClass('down2').appendTo(active);
  //         $('<em />').addClass('down3').appendTo(active);
  //         var em = active.children('em');

  //         var offset = 0;
  //         var multipleSteps = false;
  //         var left = active.position().left;
  //         var leftNew = self.parent().position().left;
  //         var toRight = (active.index() < li.index());

  //         if(toRight) {
  //             multipleSteps = (li.index() - active.index()) > 1;
  //             self.addClass('toRight');
  //             active.children('a').addClass('removeRight');
  //             em.css('--offset', leftNew - left);
  //         } else {
  //             multipleSteps = (active.index() - li.index()) > 1;
  //             self.addClass('toLeft');
  //             active.children('a').addClass('removeLeft');
  //             em.css('--offset', (left - leftNew) * -1);
  //         }

  //         em.addClass('move').toggleClass('multipleSteps', multipleSteps);

  //         setTimeout(function() {
  //             active.removeClass('active');
  //             active.children('a').removeClass('removeRight removeLeft');
  //         }, 300);

  //         setTimeout(function() {
  //             self.removeClass('toRight toLeft');
  //             self.parent().addClass('active');
  //             em.remove();
  //         }, 600);

  //     }

  // });

  return (
    <>
      <Container className="tabbar">
        <ul>
          {routes.map(props => (
            <li
              key={props.route}
              className={router.pathname === props.route ? 'active' : ''}
            >
              <Link href={props.route}>
                <a onClick={handleClick}>
                  <div>
                    <span>
                      {props.icon({
                        size: 20,
                        className: clsx({
                          noSocialIcon: !props.socialIcon
                        })
                      })}
                    </span>
                    <span>
                      {props.icon({
                        size: 20,
                        className: clsx({
                          noSocialIcon: !props.socialIcon
                        })
                      })}
                    </span>
                  </div>
                </a>
              </Link>
            </li>
          ))}
        </ul>
      </Container>
      <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
        <symbol
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          id="dashboardIcon"
        >
          <g strokeLinecap="square" strokeLinejoin="miter" strokeWidth="2">
            <path
              fill="none"
              strokeMiterlimit="10"
              d="M6.3,10C6.1,10.6,6,11.3,6,12"
              strokeLinecap="butt"
            ></path>
            <path
              fill="none"
              strokeMiterlimit="10"
              d="M18,12c0-3.3-2.7-6-6-6 c-0.7,0-1.4,0.1-2,0.3"
              strokeLinecap="butt"
            ></path>
            <line
              fill="none"
              strokeMiterlimit="10"
              x1="10.6"
              y1="10.6"
              x2="7"
              y2="7"
            ></line>
            <circle
              fill="none"
              strokeMiterlimit="10"
              cx="12"
              cy="12"
              r="2"
            ></circle>
            <circle
              fill="none"
              strokeMiterlimit="10"
              cx="12"
              cy="12"
              r="11"
            ></circle>
          </g>
        </symbol>
        <symbol
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          id="dashboardIconFilled"
        >
          <path
            stroke="none"
            d="M12,0C5.383,0,0,5.383,0,12s5.383,12,12,12s12-5.383,12-12S18.617,0,12,0z M5,12c0,0.552-0.447,1-1,1 s-1-0.448-1-1c0-1.387,0.325-2.696,0.887-3.871l1.526,1.526C5.15,10.389,5,11.177,5,12z M12,15c-1.654,0-3-1.346-3-3 c0-0.462,0.113-0.894,0.3-1.285L5.086,6.5L6.5,5.086l4.214,4.215C11.106,9.114,11.538,9,12,9c1.654,0,3,1.346,3,3S13.654,15,12,15z M20,13c-0.553,0-1-0.448-1-1c0-3.86-3.141-7-7-7c-0.823,0-1.61,0.15-2.345,0.413L8.13,3.887C9.304,3.324,10.613,3,12,3 c4.963,0,9,4.038,9,9C21,12.552,20.553,13,20,13z"
          ></path>
        </symbol>
        <symbol
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          id="cameraIcon"
        >
          <g strokeLinecap="square" strokeLinejoin="miter" strokeWidth="2">
            <rect
              x="1"
              y="5"
              transform="matrix(-1 -4.489621e-11 4.489621e-11 -1 24 27)"
              fill="none"
              strokeMiterlimit="10"
              width="22"
              height="17"
            ></rect>
            <line
              fill="none"
              strokeMiterlimit="10"
              x1="4"
              y1="2"
              x2="8"
              y2="2"
            ></line>
            <circle
              fill="none"
              strokeMiterlimit="10"
              cx="15"
              cy="14"
              r="4"
            ></circle>
            <circle
              cx="6"
              cy="10"
              r="1"
              strokeLinejoin="miter"
              strokeLinecap="square"
              stroke="none"
            ></circle>
          </g>
        </symbol>
        <symbol
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          id="cameraIconFilled"
        >
          <g stroke="none">
            <rect x="3" y="1" width="6" height="2"></rect>
            <path d="M23,4H1C0.447,4,0,4.448,0,5v17c0,0.552,0.447,1,1,1h22c0.553,0,1-0.448,1-1V5C24,4.448,23.553,4,23,4z M6,11c-0.552,0-1-0.448-1-1c0-0.552,0.448-1,1-1s1,0.448,1,1C7,10.552,6.552,11,6,11z M15,19c-2.761,0-5-2.239-5-5s2.239-5,5-5 s5,2.239,5,5S17.761,19,15,19z"></path>
          </g>
        </symbol>
        <symbol
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          id="filesIcon"
        >
          <g strokeLinecap="square" strokeLinejoin="miter" strokeWidth="2">
            <polygon
              fill="none"
              strokeMiterlimit="10"
              points="3,22 20,22 23,9 6,9 "
            ></polygon>
            <polyline
              fill="none"
              strokeMiterlimit="10"
              points="19,5 10,5 8,2 1,2 1,18 "
            ></polyline>
          </g>
        </symbol>
        <symbol
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          id="filesIconFilled"
        >
          <path
            stroke="none"
            d="M23.805,8.406C23.616,8.15,23.317,8,23,8h-2V5c0-0.552-0.447-1-1-1h-9.465L8.832,1.445 C8.646,1.167,8.334,1,8,1H1C0.447,1,0,1.448,0,2v19.978c-0.002,0.074,0.005,0.148,0.02,0.22c0.029,0.145,0.09,0.279,0.174,0.395 c0.087,0.118,0.199,0.217,0.333,0.289c0.099,0.053,0.208,0.091,0.323,0.108C0.9,22.997,0.95,23,1,23c0.008,0,0.016,0,0.023,0H19 c0.439,0,0.827-0.286,0.956-0.706l4-13C24.049,8.991,23.993,8.661,23.805,8.406z M2,3h5.465l1.703,2.555C9.354,5.833,9.666,6,10,6h9 v2H5C4.561,8,4.173,8.286,4.044,8.706L2,15.35V3z"
          ></path>
        </symbol>
        <symbol
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          id="userIcon"
        >
          <g strokeLinecap="square" strokeLinejoin="miter" strokeWidth="2">
            <path
              fill="none"
              strokeMiterlimit="10"
              d="M12,12L12,12 c-2.761,0-5-2.239-5-5V6c0-2.761,2.239-5,5-5h0c2.761,0,5,2.239,5,5v1C17,9.761,14.761,12,12,12z"
            ></path>
            <path
              fill="none"
              strokeMiterlimit="10"
              d="M22,20.908 c0-1.8-1.197-3.383-2.934-3.856C17.172,16.535,14.586,16,12,16s-5.172,0.535-7.066,1.052C3.197,17.525,2,19.108,2,20.908V23h20 V20.908z"
            ></path>
          </g>
        </symbol>
        <symbol
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          id="userIconFilled"
        >
          <g stroke="none">
            <path d="M12,13c3.309,0,6-2.691,6-6V6c0-3.309-2.691-6-6-6S6,2.691,6,6v1C6,10.309,8.691,13,12,13z"></path>
            <path d="M19.322,15.981c-4.704-1.303-9.939-1.303-14.644,0C2.513,16.581,1,18.564,1,20.805V24h22 v-3.195C23,18.564,21.487,16.581,19.322,15.981z"></path>
          </g>
        </symbol>
      </svg>
    </>
  )
}
