import styled from 'styled-components'
import { rgba, darken } from 'polished'

export const Container = styled.div`
  flex: 1;
  width: 100%;
  max-width: 1300px;
  /* max-width: 1120px; */
  height: 100%;
  /* max-height: 100vh; */
  padding: 2rem 3rem;
  margin: auto;

  /* overflow: hidden auto; */

  &::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 0px;
    width: 100vw;
    height: inherit;

    /* background: url('/images/bgProject.svg') no-repeat top center; */
    transform: translate(-51.5%, 0%);

    filter: blur(4px);
    opacity: 0.6;
    z-index: -1;
  }

  .button {
    width: 100%;
    max-width: 300px;
    height: 4rem;
    margin: 10px 0px 10px 0px;

    font-weight: 600;
    font-size: 1rem;

    background: linear-gradient(
      45deg,
      ${props => props.theme.colors.primary.normal},
      ${props => props.theme.colors.secondary} 100%
    );

    transition: transform 1s ease;
    &:hover {
      transform: scale(1.1);
    }
  }

  .header {
    height: 890px;

    display: grid;
    grid-template-columns: 530px auto;
    /* max-width: 1234px; */
    margin: 0px auto;
    padding: 0px 30px;
    position: relative;

    .title-box {
      width: 100%;
      height: 50%;

      margin-top: 12rem;

      display: grid;
      gap: 2rem;

      h1 {
        line-height: 10rem;
      }

      span {
        font-size: 3rem;
        font-weight: 600;
        overflow-wrap: break-word;

        color: ${props => props.theme.colors.text.title};
      }
    }

    .HomeMockups__Wrapper {
      position: relative;
      top: 30px;
      left: -30px;
      perspective: 5000px;
      perspective-origin: left bottom;
      transform-style: flat;

      .mockup {
        position: absolute;
        width: 183px;
        height: 120px;

        border-radius: 10px;
        box-shadow: rgb(0 0 0 / 25%) 0px 20px 40px,
          rgb(255 255 255 / 20%) 0px 0px 0px 0.5px inset;

        &.image-1 {
          top: 150px;
          left: -37px;

          background: radial-gradient(
            218.51% 281.09% at 100% 100%,
            ${props => rgba(props.theme.colors.secondary, 0.6)} 0%,
            ${props => rgba(props.theme.colors.primary.darker, 0.8)} 45.83%,
            ${props => rgba(props.theme.colors.primary.darker, 0.8)} 100%
          );
          backdrop-filter: blur(10px);
        }
        &.image-2 {
          top: 150px;
          left: 176px;

          background: linear-gradient(
            192.64deg,
            ${props => props.theme.colors.primary.darker} 12.72%,
            rgb(144, 118, 231) 54.49%,
            rgb(162, 238, 255) 100.01%
          );
          backdrop-filter: blur(10px);
        }
        &.image-3,
        &.image-4,
        &.image-5 {
          background: ${props =>
            rgba(darken(0.55, props.theme.colors.primary.normal), 0.3)};
          /* background: rgba(23, 12, 61, 0.3); */
          backdrop-filter: blur(10px) contrast(0.94);
        }
        &.image-3 {
          width: 701px;
          height: 428px;
          top: 200px;
          left: 0px;
        }
        &.image-4 {
          width: 400px;
          height: 273px;
          top: 370px;
          left: 40px;

          box-shadow: rgb(255 255 255 / 20%) 0px 0px 0px 0.5px inset;
        }
        &.image-5 {
          width: 414px;
          height: 273px;
          top: 400px;
          left: 340px;

          box-shadow: rgb(255 255 255 / 20%) 0px 0px 0px 0.5px inset;
        }

        img {
          max-width: 100%;
          padding: 0;
          margin: 0 0 1.45rem;

          opacity: 0;
          animation: 1s ease 0s 1 normal forwards running fade-in;
        }

        @media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
          box-shadow: rgb(255 255 255 / 20%) 0px 0px 0px 0.5px inset;
        }
      }

      @media (min-width: 450px) {
        & > * {
          transform: rotateY(-20deg) rotateX(20deg);
          transition: all 2s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
        }
        &:hover > * {
          transform: rotateY(0deg) rotateX(0deg);

          &.image-1 {
            transform: translate(-30px, -30px);
          }
          &.image-2 {
            transform: translate(0px, -30px);
          }
          &.image-3 {
            transform: translate(0px, 0px);
          }
          &.image-4 {
            transform: translate(-120px, 60px);
          }
          &.image-5 {
            transform: translate(30px, 30px);
          }
        }
      }
    }
  }

  section {
    width: 100%;
    max-width: 1140px;
    min-height: 50vh;
    padding-top: 6rem;
    padding-bottom: 6rem;

    border-bottom: 1px solid ${props => props.theme.colors.tertiary};

    font-family: 'Poppins', sans-serif;
    line-height: 1.8rem;
    font-weight: 400;
    letter-spacing: 0.05rem;

    .row {
      --bs-gutter-x: 1.5rem;
      --bs-gutter-y: 0;

      display: flex;
      flex-wrap: wrap;
      row-gap: 1rem;

      margin-top: calc(var(--bs-gutter-y) * -1);
      margin-right: calc(var(--bs-gutter-x) / -2);
      margin-left: calc(var(--bs-gutter-x) / -2);

      & > * {
        flex-shrink: 0;
        width: 100%;
        max-width: 100%;
        padding-right: calc(var(--bs-gutter-x) / 2);
        padding-left: calc(var(--bs-gutter-x) / 2);
        margin-top: var(--bs-gutter-y);
      }
    }
    .col {
      @media (min-width: 768px) {
        flex: 0 0 auto;
        width: 100%;
      }
      @media (min-width: 992px) {
        flex: 0 0 auto;
        width: 50%;
      }
    }

    &.bio {
      h2 {
        font-weight: 600;
        font-size: 6.2rem;
        line-height: 8rem;
        letter-spacing: -1px;
        padding-right: 0px;
        margin-bottom: 3.8rem;
      }

      p {
        font-size: 1.4rem;
        font-weight: 300;
        color: ${props => props.theme.colors.text.normal};

        & + p {
          margin-top: 2rem;
        }

        a {
          font-weight: 500;
          text-decoration: none;
          color: ${props => props.theme.colors.text.title};
        }
      }
    }

    &.projects {
      color: ${props => props.theme.colors.text.normal};

      border: none;

      span {
        opacity: 0.6;
        font-size: 1.4rem;
        font-weight: 300;
        text-align: left;
      }
      h3 {
        width: fit-content;
        margin: 2rem 0;
        font-size: 4.6rem;
        line-height: 5.2rem;
        font-weight: 500;
        text-transform: none;
      }
      p {
        font-size: 1.8rem;
        line-height: 3.9rem;
        font-weight: 300;
        text-transform: none;
      }

      .mockups {
        height: 428px;
        perspective: 5000px;
        perspective-origin: left bottom;
        transform-style: flat;

        img {
          position: absolute;

          width: 701px;
          height: 428px;
          margin: 0 0 1.45rem;

          border-radius: 10px;
          box-shadow: rgb(0 0 0 / 25%) 0px 20px 40px,
            rgb(255 255 255 / 20%) 0px 0px 0px 0.5px inset;

          transform: rotateY(27deg) rotateX(57deg);
          transition: all 2s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;

          opacity: 0;
          animation: 1s ease 0s 1 normal forwards running fade-in;
          &:nth-of-type(1) {
            top: 150px;
            left: 300px;
          }
          &:nth-of-type(2) {
            top: 50px;
            left: 176px;
          }
          &:nth-of-type(3) {
            top: 150px;
            left: 76px;
          }
        }

        &:hover > img {
          transform: rotateY(0deg) rotateX(0deg);

          &:nth-of-type(1) {
            transform: translate(80px, -120px);
          }
          &:nth-of-type(2) {
            transform: translate(-200px, -100px);
          }
          &:nth-of-type(3) {
            transform: translate(0px, 0px);
          }
        }
      }
    }

    &:nth-of-type(3) {
      display: flex;
      flex-direction: column;
      justify-content: center;

      p {
        margin-bottom: 8rem;
      }
      img {
        width: 100%;
        max-width: 900px;
        margin: auto;

        border-radius: 10px;
        box-shadow: rgb(0 0 0 / 25%) 0px 0px 40px 20px;
      }
    }

    &:nth-of-type(4) {
      p {
        margin-bottom: 8rem;
      }
      img {
        width: 100%;
        box-shadow: rgb(0 0 0 / 25%) 0px 0px 40px 20px;
      }
    }

    &:nth-of-type(5),
    &:nth-of-type(6) {
      .alert {
        transform: translateY(4rem);
        display: flex;
        align-items: flex-end;

        svg {
          margin-left: 1rem;
        }
      }
      .swiper-container {
        width: 100vw;
        height: 100%;
        margin-top: 8rem;

        position: relative;
        left: -13%;
      }

      .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: center;

        cursor: grab;

        &:active {
          cursor: grabbing;
        }
      }
      img {
        /* width: 30rem; */
        width: 100%;
        border-radius: 30px;
      }
    }
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    90% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @media only screen and (max-width: 1200px) {
    padding: 40px;
  }

  @media only screen and (max-width: 768px) {
    padding: 30px 20px;

    .header {
      grid-template-columns: 360px auto;
    }
  }

  @media only screen and (max-width: 600px) {
    padding: 0rem 2rem;

    .button {
      /* width: 18rem; */

      font-size: 1rem;
      font-weight: 500;
    }

    .header {
      height: 100vh;

      padding: 0;
      grid-template-columns: 1fr;
      grid-template-rows: auto auto;

      .title-box {
        margin-top: 8rem;

        h1 {
          font-size: 4.3rem;
          line-height: 4.3rem;
          letter-spacing: -2px;
        }

        span {
          font-size: 1.8rem;
          font-weight: 500;
          line-height: 130%;
        }
      }
      .HomeMockups__Wrapper {
        top: -16rem;
        left: -82px;
        transform: scale(0.5);
      }
    }

    section {
      &.bio {
        h2 {
          font-weight: 600;
          font-size: 50px;
          line-height: 55px;
          letter-spacing: -1px;
          padding-right: 0px;
          margin-bottom: 50px;
        }
      }

      &.projects {
        h3 {
          font-size: 40px;
          line-height: 45px;
          letter-spacing: 0px;
        }
        span,
        p {
          font-size: 16px;
          line-height: 26px;
        }
      }
    }
  }
`
